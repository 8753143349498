import { AiAssistantChatView } from '@/aiAssistant/AiAssistantChatView.molecule';
import { AiAssistantIntroduction } from '@/aiAssistant/AiAssistantIntroduction.atom';
import { AiAssistantPrompt } from '@/aiAssistant/AiAssistantPrompt.molecule';
import { AiAssistantToolbar } from '@/aiAssistant/AiAssistantToolbar.atom';
import { ChatHistory } from '@/aiAssistant/ChatHistory.atom';
import { AgentType, AssistantOrigin, ChatMessage } from '@/aiAssistant/aiAssistant.types';
import { useChatStream } from '@/aiAssistant/useChatStream';
import {
  sqAiAssistantStore,
  sqAnnotationStore,
  sqWorkbookStore,
  sqWorksheetStore,
  sqWorkstepsStore,
} from '@/core/core.stores';
import { useFlux } from '@/core/hooks/useFlux.hook';
import utilities from '@/core/utilities';
import { doTrack } from '@/track/track.service';
import { successToast } from '@/utilities/toast.utilities';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { v1 as timeOrderedUUID } from 'uuid';
import { setAIAssistantMessages } from '@/aiAssistant/aiAssistant.actions';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { fetchDocument, setDocument } from '@/annotation/annotation.actions';

interface AiAssistantProps {
  openHistoryCallback?: (grow: boolean) => void;
  historyShown?: boolean;
  ref?: React.MutableRefObject<HTMLDivElement>;
}

export const AiAssistant = React.forwardRef<HTMLDivElement, AiAssistantProps>(
  ({ openHistoryCallback, historyShown = false }, ref) => {
    const aiAssistantMessages = useFluxPath(sqAiAssistantStore, () => sqAiAssistantStore.messages);
    const chatIdRef = useRef(aiAssistantMessages[0]?.chatId || timeOrderedUUID());
    const [messages, setMessages] = useState<ChatMessage[]>(aiAssistantMessages);
    const [manualAgentSelection, setManualAgentSelection] = useState<AgentType>(
      (messages[messages.length - 1]?.agentType as AgentType) || 'general',
    );
    const displayRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
    const [showHistory, setShowHistory] = useState(historyShown);
    const [showInformation, setShowInformation] = useState(false);
    const [introKey, setIntroKey] = useState(Math.random());
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { isReportBinder } = useFlux(sqWorkbookStore);
    const origin = useRef<AssistantOrigin>('homescreen');
    const { submitPrompt, abortRequest, isRunning, updateAgent, scrollToBottom } = useChatStream(
      manualAgentSelection,
      chatIdRef,
      setMessages,
      displayRef,
    );
    const journalDocumentId = useFluxPath(sqAnnotationStore, () => sqAnnotationStore.id);
    const isJournalTabActive = useFluxPath(sqWorksheetStore, () => {
      const tabSet = sqWorksheetStore.getTabset('sidebar');
      return tabSet.tabs[tabSet.activeTabIndex] === 'annotate';
    });

    const setAgent = (agent: AgentType) => {
      setManualAgentSelection(agent);
      updateAgent(agent);
    };

    const onChatSelected = (chatId: string, messages: ChatMessage[], origin?: AssistantOrigin) => {
      chatIdRef.current = chatId;
      const messagesWithOrigin = messages.map((message) => ({ origin, ...message }));
      setMessages(messagesWithOrigin);
      const lastAgent = messagesWithOrigin[messagesWithOrigin.length - 1]?.agentType;
      if (lastAgent) setAgent(lastAgent as AgentType);
    };

    useEffect(() => {
      if (pathname.includes('/workbook/')) {
        if (isReportBinder) {
          origin.current = 'organizer';
        } else {
          origin.current = 'workbench';
        }
      } else {
        origin.current = 'homescreen';
      }
    }, [pathname, isReportBinder]);

    useEffect(() => {
      if (!isRunning) setAIAssistantMessages(messages);
    }, [isRunning, messages]);

    useEffect(() => {
      !isRunning &&
        manualAgentSelection === 'workbench' &&
        journalDocumentId &&
        isJournalTabActive &&
        fetchDocument(journalDocumentId).then(({ document }) => document && setDocument(document, false, true));
    }, [isRunning]);

    return (
      <>
        <div className={`flexRowContainer flexFill aiAssistant height-maximum`} ref={ref}>
          <AiAssistantToolbar
            isRunning={isRunning}
            expandHistory={() => {
              setShowHistory(!showHistory);
              openHistoryCallback && openHistoryCallback(!showHistory);
            }}
            newChat={() => {
              setMessages([]);
              setShowInformation(false);
              setIntroKey(Math.random());
              chatIdRef.current = timeOrderedUUID();
              doTrack('AiAssistant', 'new chat');
            }}
          />
          <div className="flexColumnContainer flexFill">
            {showHistory && (
              <ChatHistory
                onChatSelected={onChatSelected}
                newMessagesAvailable={!isRunning}
                selectedChatId={chatIdRef.current}
              />
            )}
            <div className="flexFill flexRowContainer">
              <AiAssistantChatView
                submitPrompt={submitPrompt}
                origin={origin.current}
                onAgentChange={(agent, messageIdx) => {
                  if (messageIdx > 0) {
                    const message = messages[messageIdx - 1];

                    if (message.role === 'user' && message.agentType !== agent) {
                      setAgent(agent);

                      const context = sqWorkstepsStore.current.id ? sqWorkstepsStore.current : undefined;
                      setMessages((prevMessages) => prevMessages.slice(0, messageIdx - 1));
                      submitPrompt(message.dialog, origin.current, context, message.id);
                    }
                  }
                }}
                selectedAgent={manualAgentSelection}
                isRunning={isRunning}
                messages={messages}
                insertFormulaSnippet={(textToCopy: string) => {
                  utilities.copyTextToClipboard(textToCopy);
                  successToast({ messageKey: 'AI_ASSISTANT.COPY_SUCCESS' });
                }}
                scrollToBottom={scrollToBottom}
                ref={displayRef}
                showInformation={showInformation}>
                {!showInformation && (
                  <AiAssistantIntroduction
                    key={introKey}
                    origin={origin.current}
                    submitPrompt={submitPrompt}
                    selectedAgent={manualAgentSelection}
                  />
                )}
              </AiAssistantChatView>
              <AiAssistantPrompt
                submitPrompt={submitPrompt}
                isRunning={isRunning}
                origin={origin.current}
                abortRequest={abortRequest}
                selectedAgent={manualAgentSelection}
                onAgentChange={(agentKey: AgentType) => setAgent(agentKey)}
              />
              <div
                className="flexColumnContainer flexCenter promptFooter aboutLink cursorPointer"
                onClick={() => {
                  setShowInformation((previous) => {
                    if (!previous) doTrack('AiAssistant', 'info');
                    return !previous;
                  });
                }}>
                {t(showInformation ? 'CLOSE' : 'AI_ASSISTANT.INFORMATION.ABOUT')}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);
