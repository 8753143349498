import { Message } from '@/aiAssistant/Message.molecule';
import { AgentType, AssistantOrigin, ChatMessage } from '@/aiAssistant/aiAssistant.types';
import React from 'react';
import { AiAssistantInformation } from '@/aiAssistant/AiAssistantInformation.atom';

interface AiAssistantChatViewProps {
  messages: ChatMessage[];
  insertFormulaSnippet: (snippet: string) => void;
  children?: React.ReactNode;
  isRunning: boolean;
  selectedAgent?: AgentType;
  onAgentChange?: (agent: AgentType, messageIdx: number) => void;
  origin: AssistantOrigin;
  submitPrompt: (prompt: string, origin: AssistantOrigin, context?: Record<string, unknown>) => void;
  scrollToBottom: () => void;
  showInformation?: boolean;
}

export const AiAssistantChatView = React.forwardRef<HTMLDivElement, AiAssistantChatViewProps>(
  (
    {
      isRunning,
      messages,
      insertFormulaSnippet,
      selectedAgent,
      onAgentChange,
      origin,
      submitPrompt,
      scrollToBottom,
      children,
      showInformation,
    },
    ref,
  ) => {
    const length = messages.length - 1;

    if (showInformation) return <AiAssistantInformation />;

    return (
      <div className="flexColumnContainer flexFill">
        {messages.length === 0 ? (
          <div className="flexRowContainer overflowAuto mt15 width-maximum">{children}</div>
        ) : (
          <div ref={ref} className="flexRowContainer overflowAuto pr5 width-maximum">
            {messages.map((message, idx) => (
              <Message
                key={message.id}
                message={message}
                isRunning={isRunning}
                last={idx === length}
                insertFormulaSnippet={insertFormulaSnippet}
                selectedAgent={message.agentType ? (message.agentType as AgentType) : selectedAgent}
                onAgentChange={(agent: AgentType) => {
                  onAgentChange && onAgentChange(agent, idx);
                }}
                origin={origin}
                submitPrompt={submitPrompt}
                scrollToBottom={scrollToBottom}
              />
            ))}
          </div>
        )}
      </div>
    );
  },
);
